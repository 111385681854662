#cycle:after {
    -webkit-animation: cycle 4s infinite; /* Safari 4+ */
    -moz-animation: cycle 4s infinite; /* Fx 5+ */
    -o-animation: cycle 4s infinite; /* Opera 12+ */
    animation: cycle 4s infinite; /* IE 10+, Fx 29+ */
  }

@keyframes cycle {
  0% {
    content: "Corgi";
  }
  33% {
    content: "Shih Tzu";
  }
  66% {
    content: "Pug";
  }
  100% {
    content: "Dachshund";
  }
}


.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes blinking {
  0% {
    background-color: #ff3d50;
    border: 5px solid #ffca40;
  }
  /* ADD MORE KEYFRAMES IF YOU WANT
  50% {
    background-color: #55d66b;
    border: 5px solid #126620;
  } */
  100% {
    background-color: #222291;
    border: 5px solid #6565f2;
  }
}
 
/* (B) ATTACH BLINKING KEYFRAMES TO CONTAINER */
/* animation: name | time | iterations */
#demo {
  height: 100%;
  width: 100%;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  opacity: 0.9;
  animation: blinking 1s infinite;
}