@font-face {
  font-family: "Pixel";
  src: local("Pixel"), url(../../assets/FreePixel.ttf) format("truetype");
}

@font-face {
  font-family: "Bagor";
  src: url("../../assets/Bagor-RoundNormal.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inndam";
  src: url("../../assets/Inndam-Bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Recoleta";
  src: local("Recoleta"),
    url(../../assets/Recoleta-Medium.ttf) format("truetype");
}

html,
body {
  min-height: 100%;
  font-family: Pixel;
  background-color: #f5f5f5;
  height: 100vh !important;
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  /* background-color: rgb(15, 23, 42); */
  /* color: white; */
}

.bg-image {
  /* background-image: url(); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
}

#x {
  position: absolute;
  background: transparent;
  color: white;
  top: -24px;
  right: -24px;
  border-radius: 1em;
  border: none;
}

@media (min-width: 992px) {
  .bg-image {
    /* height:100vh; */
  }
}

.dark {
  background-color: rgb(15, 23, 42);
  color: white;
  min-height: 100vh;
}

.dark-black {
  background-color: black;
  color: white;
  min-height: 100vh;
}

.dark-color {
  background-color: rgb(15, 23, 42);
  color: white;
  border-color: white;
}

.dark .dropdown-menu {
  background-color: #1e293b;
  color: #fff;
  border: solid 1px black;
}

.dark-black .dropdown-menu {
  background-color: black;
  color: #fff;
  border: solid 1px white;
}

.dark-color .card {
  background-color: rgb(15, 23, 42);
  color: white;
}

.h-100vh {
  min-height: 100vh !important;
}

.h-100vh-desktop {
  /* min-height: 100vh !important; */
}

.body-wrapper {
  padding-top: 20px;
}

.classic-container {
  background-color: #f5f5f5;
}

.top-index {
  z-index: 42;
}

.balance-text {
  text-align: center;
}

@media (max-width: 992px) {
  .h-sm-100 {
    height: 100% !important;
  }
}

@media (min-width: 992px) {
  .h-100-desktop {
    height: 100% !important;
  }

  .h-100vh-desktop {
    min-height: 100vh;
  }
}

@media (min-width: 992px) {
  .body-wrapper {
    /* display: flex; */
    /* align-items: center; */
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .logo {
  }

  .form-signin {
    padding: 15px;
  }

  .play {
    width: 80vw;
  }

  .balance-text {
    text-align: right;
  }
}

.form-signin {
  width: 100%;
  max-width: 420px;
  margin: auto;
}

.play {
  width: 90vw;
  max-width: 1000px;
  margin: auto;
  z-index: 5;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.logo {
  height: 200px;
  width: 200px;
}

.bountiful-logo {
  max-height: 20vw;
}

@media only screen and (max-width: 600px) {
  .bountiful-logo {
    max-height: 50vw;
  }
}

.bountiful-container {
  width: 100%;
  margin: auto;
}

.faq {
  width: 69vw;
  max-width: 1000px;
  margin: 32px auto;
  z-index: 1;
}

.coin-flipping {
  width: 100%;
}

.coin-flipping-square {
  width: 200px;
  height: 200px;
}

@media only screen and (max-width: 600px) {
  .logo {
    height: 128px;
    width: 128px;
  }

  .faq {
    padding: 0;
    width: 90vw;
  }
}

.accordion-button:focus {
  border-color: transparent;
}

.accordion-button:not(.collapsed) {
  color: black;
  background-color: #d3d3d3;
}

.accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23a3acb9'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-ban {
  cursor: not-allowed !important;
}

.toggle-button {
  border: solid 3px transparent;
}

.double-or-nothing-button {
  transition: transform 0.35s ease-in-out, box-shadow 0.25s linear;
}

.double-or-nothing-button:hover {
  transform: scale(1.05);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.double-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  border: solid 0px transparent;
}

.selected {
  border: solid 3px black !important;
}

.dark .selected {
  border: solid 3px white !important;
}

.dark-black .selected {
  border: solid 3px #ffdf58 !important;
}

p {
  white-space: pre-wrap;
}

.text-twitter {
  color: #1da1f2 !important;
}

.text-discord {
  color: #5865f2 !important;
}

.bg-discord {
  background-color: #5865f2 !important;
  color: white;
}

.social-icons {
  position: relative;
  /* top: 35px; */
  z-index: 2;
}

/* .social-icons .btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 4rem;
  width: 4rem;
  border-radius: 100rem;
} */

@media (min-width: 992px) {
  .social-icons {
    position: absolute;
    /* height: 100%; */
    top: 35px;
    right: 1.8rem;
    width: auto;
  }

  .social-icons-hide-live-feed {
    position: absolute;
    /* height: 100%; */
    top: 0px;
    right: 1.8rem;
    width: auto;
  }

  .social-icons-left {
    position: absolute;
    /* height: 100%; */
    top: 40px;
    left: 1.8rem;
    width: auto;
  }

  .social-icons-left-hide-feed {
    position: absolute;
    /* height: 100%; */
    top: 0px;
    left: 1.8rem;
    width: auto;
  }

  .social-icons-bottom-left {
    position: fixed;
    /* height: 100%; */
    bottom: 1.8rem;
    left: 1.8rem;
    width: auto;
    z-index: 10;
  }

  .social-icons-bottom-right {
    position: fixed;
    /* height: 100%; */
    bottom: 1.8rem;
    right: 1.8rem;
    width: auto;
    z-index: 10;
  }
}

.toast-header {
  color: black !important;
  background-color: #ffdf58 !important;
}

.toast-container {
  z-index: 1050;
}

.toast-body {
  text-align: left !important;
  background-color: #fafafa;
}

.dark .toast-body {
  background-color: rgb(15, 23, 42);
  color: white;
  border: solid 1px white;
  /* border-top: none; */
}

.dark-black .toast-body {
  background-color: black;
  color: white;
  border: solid 1px white;
  /* border-top: none; */
}

.sol-balance {
  animation: pop 0.3s linear 1;
}

@keyframes pop {
  50% {
    transform: scale(1.2);
  }
}

.pop {
  animation: pop 0.3s linear 1;
}

.leaderboard-list {
  width: 469px;
}

.dark .leaderboard-list {
  border-color: black;
}

.form-signin2 {
  width: 100%;
  max-width: 600px;
  padding-top: 15px;
  margin: auto;
}

.shortcut-row {
}

.profile-picture {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.profile-picture-md .image {
  height: 2.4em;
  width: 2.4em;
  object-fit: cover;
}

.profile-picture-sm .image {
  height: 0.6em !important;
  width: 0.6em !important;
  font-size: 0.6rem !important;
}

.image-xs {
  width: 32px;
  height: 32px;
}

.image-s {
  width: 48px;
  height: 48px;
}

.image-m {
  width: 64px;
  height: 64px;
}

.time-in-row {
  position: absolute;
  right: 10px;
  bottom: 0;
}

.time-placeholder {
  height: 1%;
  margin-bottom: 0.5rem;
}

.h-1per {
  height: 1%;
}

.profile-picture .image {
  width: 2rem !important;
  height: 2rem !important;
}

@media (max-width: 576px) {
  .shortcut-row {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
  }

  .shortcut-row .btn {
    width: 100%;
  }

  .shortcut-row .leaderboard-list {
    width: 100%;
  }

  .toolbar {
    justify-content: center !important;
  }
}

.toolbar {
  justify-content: flex-end;
}

.profile-picture .image-large {
  width: 128px;
  height: 128px;
}

.dark-black .list-group-item:hover {
  background-color: rgba(0, 0, 0, 0.5) !important;
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.dark .list-group-item:hover {
  background-color: rgba(108, 122, 137, 0.95) !important;
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.light .list-group-item:hover {
  background-color: rgba(236, 236, 236, 0.95) !important;
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.list-group-item a {
  text-decoration: none;
  color: black;
}

/* dark black */

/* .dark-black .body-wrapper {
  background-color: black;
} */

.dark-black .classic-container {
  background-color: black;
}

.dark-black .list-group {
  border: 1px solid white !important;
}

.dark-black .list-group-item {
  background-color: black;
  color: white;
}

.dark-black .btn-dark {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.dark-black .btn-outline-dark {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.dark-black .btn-transparent {
  color: #f8f9fa;
}

/* end dark black */

/* .dark .body-wrapper {
  background-color: rgb(15, 23, 42);
} */

.dark .classic-container {
  background-color: rgb(15, 23, 42);
}

.dark .list-group-item {
  background-color: rgb(30, 41, 59);
  color: white;
}

.dark-color .list-group-item {
  background-color: rgb(30, 41, 59);
  color: white;
}

.dark .list-group {
  border: 1px solid black !important;
}

.dark .donation-card {
  background-color: rgb(30, 41, 59);
  color: white;
}

.dark .btn-dark {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.dark .btn-outline-dark {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.dark .btn-transparent {
  color: #f8f9fa;
}

body.dark .modal-content {
  background-color: #1e2229;
}

body.dark .modal-content .modal-header,
body.dark .modal-content .modal-footer {
  border-color: #424242;
}

body.dark .form-control {
  background-color: #282d36;
  border-color: #282d36;
}

.dark-color .form-control {
  color: white;
  border: solid 1px white;
  background-color: rgb(30, 41, 59);
}

body.dark .card {
  background-color: rgb(15, 23, 42);
  color: white;
  border: solid 1px white;
}

body.dark .form-control {
  color: white;
  border: solid 1px white;
  background-color: rgb(30, 41, 59);
}

body.dark .double-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(255, 255, 255, 0.1),
    0 3px 6px rgba(255, 255, 255, 0.08);
}

.dark .double-or-nothing-button:hover {
  box-shadow: 0 7px 14px rgba(255, 255, 255, 0.1),
    0 3px 6px rgba(255, 255, 255, 0.08) !important;
}

.button-fx:hover {
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(255, 255, 255, 0.1),
    0 3px 6px rgba(255, 255, 255, 0.08);
}

body.dark .selected {
  border: solid 3px white;
}

body.dark .btn-warning {
  color: black !important;
  background-color: #ffdf58 !important;
}

.imageWrapper {
  position: relative;
  /* width: 300px; */
  /* height: 300px; */
}

.imageWrapper img {
  display: block;
  object-fit: cover;
}

.imageWrapper .cornerLink {
  opacity: 0;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 2px 0px;
  color: #ffffff;
  background: #000000;
  text-decoration: none;
  text-align: center;
  -webkit-transition: opacity 500ms;
  -moz-transition: opacity 500ms;
  -o-transition: opacity 500ms;
  transition: opacity 500ms;
}

.imageWrapper:hover .cornerLink {
  opacity: 0.8;
}

.dark a {
  color: white;
}

.dark-black a {
  color: white;
}

.shake-it {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.progress {
  height: 0.5rem;
  background-color: transparent;
}

video.bg-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  z-index: 0;
}

.z-index-10 {
  z-index: 10;
}

.masthead {
  position: relative;
  overflow: hidden;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.masthead:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.masthead .masthead-content {
  position: relative;
  max-width: 40rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.masthead .masthead-content h1,
.masthead .masthead-content .h1 {
  font-size: 2.5rem;
}

.masthead .masthead-content p {
  font-size: 1.2rem;
}

.masthead .masthead-content p strong {
  font-weight: 700;
}

.masthead .masthead-content .input-group-newsletter input {
  height: auto;
  width: 100%;
  font-size: 1rem;
  padding: 1rem;
}

.masthead .masthead-content .input-group-newsletter button {
  font-size: 0.85rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: calc(1rem + 2px);
}

@media (min-width: 992px) {
  .masthead {
    height: 100%;
    width: 100%;
    min-height: 0;
    padding-bottom: 0;
  }

  .masthead:before {
    /* transform: skewX(-9deg);
    transform-origin: top right; */
  }

  .masthead .masthead-content {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .masthead .masthead-content h1,
  .masthead .masthead-content .h1 {
    font-size: 3.5rem;
  }

  .masthead .masthead-content p {
    font-size: 1.3rem;
  }
}

@media (min-width: 1200px) {
  .masthead {
    width: 100%;
  }
}

.players-card-header {
  background-color: rgba(0, 0, 0, 0.2);
}

.no-decoration {
  text-decoration: none;
  color: inherit;
}

#dropdown-button-dark-example1 {
  margin-left: 0.5rem;
  height: 100%;
  display: flex;
}

#dropdown-button-dark-example1::after {
  display: none !important;
}

.whale-text {
  text-decoration: underline;
  font-size: 17px;
}

.inp {
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: inherit;
  padding: 5px 10px;
  outline: none;
  background-color: transparent;
  font-size: 32px;
  max-width: 128px;
  text-align: center;
  color: inherit;
}

/* [placeholder]:focus::-webkit-input-placeholder {
  transition: text-indent 0.4s 0.4s ease; 
  text-indent: -100%;
  opacity: 1;
} */

.give-button {
  width: 480px;
  max-width: 100%;
}

.h-1rem {
  height: 1rem;
}

.progress .bg-light {
  color: black;
}

.navbar-brand-disclaimer {
  font-size: 22px;
}

@media (max-width: 1200px) {
  .navbar-brand-disclaimer {
    font-size: 18px;
  }
}

@media (max-width: 576px) {
  .navbar-brand-disclaimer {
    font-size: 16px;
  }
}

.dot1 {
  animation: visibility 3s linear infinite;
}

@keyframes visibility {
  0% {
    opacity: 1;
  }

  65% {
    opacity: 1;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.dot2 {
  animation: visibility2 3s linear infinite;
}

@keyframes visibility2 {
  0% {
    opacity: 0;
  }

  21% {
    opacity: 0;
  }

  22% {
    opacity: 1;
  }

  65% {
    opacity: 1;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.dot3 {
  animation: visibility3 3s linear infinite;
}

@keyframes visibility3 {
  0% {
    opacity: 0;
  }

  43% {
    opacity: 0;
  }

  44% {
    opacity: 1;
  }

  65% {
    opacity: 1;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.cssload-container {
  width: 100%;
  height: 32px;
  text-align: center;
}

:is(.dark, .dark-black, .dark-color, .dark-black-color) .cssload-zenith {
  box-shadow: 3px 3px 1px rgb(255, 255, 255) !important;
}

.cssload-zenith {
  width: 32px;
  height: 32px;
  margin: 0 auto;
  border-radius: 50%;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  box-shadow: 3px 3px 1px rgb(0, 0, 0);
  animation: cssload-spin 690ms infinite linear;
  -o-animation: cssload-spin 690ms infinite linear;
  -ms-animation: cssload-spin 690ms infinite linear;
  -webkit-animation: cssload-spin 690ms infinite linear;
  -moz-animation: cssload-spin 690ms infinite linear;
}

@keyframes cssload-spin {
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes cssload-spin {
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-ms-keyframes cssload-spin {
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes cssload-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes cssload-spin {
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
.la-ball-8bits,
.la-ball-8bits > div {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.la-ball-8bits {
  display: block;
  font-size: 0;
  color: #333;
}

.la-ball-8bits.la-dark {
  color: #333;
}

:is(.dark, .dark-black) .la-ball-8bits {
  color: #fff;
}

.la-ball-8bits > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}

.la-ball-8bits {
  width: 12px;
  height: 12px;
}

.la-ball-8bits > div {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 4px;
  border-radius: 0;
  opacity: 0;
  -webkit-transform: translate(100%, 100%);
  -moz-transform: translate(100%, 100%);
  -ms-transform: translate(100%, 100%);
  -o-transform: translate(100%, 100%);
  transform: translate(100%, 100%);
  -webkit-animation: ball-8bits 1s 0s ease infinite;
  -moz-animation: ball-8bits 1s 0s ease infinite;
  -o-animation: ball-8bits 1s 0s ease infinite;
  animation: ball-8bits 1s 0s ease infinite;
}

.la-ball-8bits > div:nth-child(1) {
  -webkit-animation-delay: -0.9375s;
  -moz-animation-delay: -0.9375s;
  -o-animation-delay: -0.9375s;
  animation-delay: -0.9375s;
}

.la-ball-8bits > div:nth-child(2) {
  -webkit-animation-delay: -0.875s;
  -moz-animation-delay: -0.875s;
  -o-animation-delay: -0.875s;
  animation-delay: -0.875s;
}

.la-ball-8bits > div:nth-child(3) {
  -webkit-animation-delay: -0.8125s;
  -moz-animation-delay: -0.8125s;
  -o-animation-delay: -0.8125s;
  animation-delay: -0.8125s;
}

.la-ball-8bits > div:nth-child(4) {
  -webkit-animation-delay: -0.75s;
  -moz-animation-delay: -0.75s;
  -o-animation-delay: -0.75s;
  animation-delay: -0.75s;
}

.la-ball-8bits > div:nth-child(5) {
  -webkit-animation-delay: -0.6875s;
  -moz-animation-delay: -0.6875s;
  -o-animation-delay: -0.6875s;
  animation-delay: -0.6875s;
}

.la-ball-8bits > div:nth-child(6) {
  -webkit-animation-delay: -0.625s;
  -moz-animation-delay: -0.625s;
  -o-animation-delay: -0.625s;
  animation-delay: -0.625s;
}

.la-ball-8bits > div:nth-child(7) {
  -webkit-animation-delay: -0.5625s;
  -moz-animation-delay: -0.5625s;
  -o-animation-delay: -0.5625s;
  animation-delay: -0.5625s;
}

.la-ball-8bits > div:nth-child(8) {
  -webkit-animation-delay: -0.5s;
  -moz-animation-delay: -0.5s;
  -o-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.la-ball-8bits > div:nth-child(9) {
  -webkit-animation-delay: -0.4375s;
  -moz-animation-delay: -0.4375s;
  -o-animation-delay: -0.4375s;
  animation-delay: -0.4375s;
}

.la-ball-8bits > div:nth-child(10) {
  -webkit-animation-delay: -0.375s;
  -moz-animation-delay: -0.375s;
  -o-animation-delay: -0.375s;
  animation-delay: -0.375s;
}

.la-ball-8bits > div:nth-child(11) {
  -webkit-animation-delay: -0.3125s;
  -moz-animation-delay: -0.3125s;
  -o-animation-delay: -0.3125s;
  animation-delay: -0.3125s;
}

.la-ball-8bits > div:nth-child(12) {
  -webkit-animation-delay: -0.25s;
  -moz-animation-delay: -0.25s;
  -o-animation-delay: -0.25s;
  animation-delay: -0.25s;
}

.la-ball-8bits > div:nth-child(13) {
  -webkit-animation-delay: -0.1875s;
  -moz-animation-delay: -0.1875s;
  -o-animation-delay: -0.1875s;
  animation-delay: -0.1875s;
}

.la-ball-8bits > div:nth-child(14) {
  -webkit-animation-delay: -0.125s;
  -moz-animation-delay: -0.125s;
  -o-animation-delay: -0.125s;
  animation-delay: -0.125s;
}

.la-ball-8bits > div:nth-child(15) {
  -webkit-animation-delay: -0.0625s;
  -moz-animation-delay: -0.0625s;
  -o-animation-delay: -0.0625s;
  animation-delay: -0.0625s;
}

.la-ball-8bits > div:nth-child(16) {
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
}

.la-ball-8bits > div:nth-child(1) {
  top: -100%;
  left: 0;
}

.la-ball-8bits > div:nth-child(2) {
  top: -100%;
  left: 33.3333333333%;
}

.la-ball-8bits > div:nth-child(3) {
  top: -66.6666666667%;
  left: 66.6666666667%;
}

.la-ball-8bits > div:nth-child(4) {
  top: -33.3333333333%;
  left: 100%;
}

.la-ball-8bits > div:nth-child(5) {
  top: 0;
  left: 100%;
}

.la-ball-8bits > div:nth-child(6) {
  top: 33.3333333333%;
  left: 100%;
}

.la-ball-8bits > div:nth-child(7) {
  top: 66.6666666667%;
  left: 66.6666666667%;
}

.la-ball-8bits > div:nth-child(8) {
  top: 100%;
  left: 33.3333333333%;
}

.la-ball-8bits > div:nth-child(9) {
  top: 100%;
  left: 0;
}

.la-ball-8bits > div:nth-child(10) {
  top: 100%;
  left: -33.3333333333%;
}

.la-ball-8bits > div:nth-child(11) {
  top: 66.6666666667%;
  left: -66.6666666667%;
}

.la-ball-8bits > div:nth-child(12) {
  top: 33.3333333333%;
  left: -100%;
}

.la-ball-8bits > div:nth-child(13) {
  top: 0;
  left: -100%;
}

.la-ball-8bits > div:nth-child(14) {
  top: -33.3333333333%;
  left: -100%;
}

.la-ball-8bits > div:nth-child(15) {
  top: -66.6666666667%;
  left: -66.6666666667%;
}

.la-ball-8bits > div:nth-child(16) {
  top: -100%;
  left: -33.3333333333%;
}

.la-ball-8bits.la-sm {
  width: 6px;
  height: 6px;
}

.la-ball-8bits.la-sm > div {
  width: 2px;
  height: 2px;
}

.la-ball-8bits.la-2x {
  width: 24px;
  height: 24px;
}

.la-ball-8bits.la-2x > div {
  width: 8px;
  height: 8px;
}

.la-ball-8bits.la-3x {
  width: 36px;
  height: 36px;
}

.la-ball-8bits.la-3x > div {
  width: 12px;
  height: 12px;
}

/*
  * Animation
  */
@-webkit-keyframes ball-8bits {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  51% {
    opacity: 0;
  }
}

@-moz-keyframes ball-8bits {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  51% {
    opacity: 0;
  }
}

@-o-keyframes ball-8bits {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  51% {
    opacity: 0;
  }
}

@keyframes ball-8bits {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  51% {
    opacity: 0;
  }
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animate-flicker {
  -webkit-animation: flickerAnimation 0.5s infinite;
  -moz-animation: flickerAnimation 0.5s infinite;
  -o-animation: flickerAnimation 0.5s infinite;
  animation: flickerAnimation 0.5s infinite;
}

.text-twitter-blue {
  color: #1da1f2;
}

.bg-twitter-blue {
  background-color: #1da1f2;
}

.btn-twitter {
  color: #fff;
  background-color: #1da1f2;
  border-color: #1da1f2;
}

.btn-twitter:hover {
  color: #fff;
  background-color: #1da1f2;
  border-color: #1da1f2;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #000;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}

.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}

@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }

  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

.loader {
  --color: black;
  --size-mid: 6vmin;
  --size-dot: 1.5vmin;
  --size-bar: 0.4vmin;
  --size-square: 3vmin;

  display: block;
  position: relative;
  width: 50%;
  display: grid;
  place-items: center;
}

.loader::before,
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
}

/**
	loader --1
**/
.loader.--1::before {
  width: var(--size-mid);
  height: var(--size-mid);
  border: 4px solid var(--color);
  border-top-color: transparent;
  border-radius: 50%;
  animation: loader-1 1s linear infinite;
}

.loader.--1::after {
  width: calc(var(--size-mid) - 2px);
  height: calc(var(--size-mid) - 2px);
  border: 2px solid transparent;
  border-top-color: var(--color);
  border-radius: 50%;
  animation: loader-1 0.6s linear reverse infinite;
}

@keyframes loader-1 {
  100% {
    transform: rotate(1turn);
  }
}

/**
	loader --2
**/
.loader.--2::before,
.loader.--2::after {
  width: var(--size-dot);
  height: var(--size-dot);
  background-color: var(--color);
  border-radius: 50%;
  opacity: 0;
  animation: loader-2 0.8s cubic-bezier(0.2, 0.32, 0, 0.87) infinite;
}

.loader.--2::after {
  animation-delay: 0.3s;
}

@keyframes loader-2 {
  0%,
  80%,
  100% {
    opacity: 0;
  }

  33% {
    opacity: 1;
  }

  0%,
  100% {
    transform: translateX(-4vmin);
  }

  90% {
    transform: translateX(4vmin);
  }
}

/**
	loader --3
**/
.loader.--3::before,
.loader.--3::after {
  width: var(--size-dot);
  height: var(--size-dot);
  background-color: var(--color);
  border-radius: 50%;
  animation: loader-3 1.2s ease-in-out infinite;
}

.loader.--3::before {
  left: calc(50% - 1.6vmin - var(--size-dot));
}

.loader.--3::after {
  left: calc(50% + 1.6vmin);
  animation-delay: -0.4s;
}

@keyframes loader-3 {
  0%,
  100% {
    transform: translateY(-2.6vmin);
  }

  44% {
    transform: translateY(2.6vmin);
  }
}

/**
	loader --4
**/
.loader.--4::before {
  height: var(--size-bar);
  width: 6vmin;
  background-color: var(--color);
  animation: loader-4 0.8s cubic-bezier(0, 0, 0.03, 0.9) infinite;
}

@keyframes loader-4 {
  0%,
  44%,
  88.1%,
  100% {
    transform-origin: left;
  }

  0%,
  100%,
  88% {
    transform: scaleX(0);
  }

  44.1%,
  88% {
    transform-origin: right;
  }

  33%,
  44% {
    transform: scaleX(1);
  }
}

/**
	loader --5
**/
.loader.--5::before,
.loader.--5::after {
  height: 3vmin;
  width: var(--size-bar);
  background-color: var(--color);
  animation: loader-5 0.6s cubic-bezier(0, 0, 0.03, 0.9) infinite;
}

.loader.--5::before {
  left: calc(50% - 1vmin);
  top: calc(50% - 3vmin);
}

.loader.--5::after {
  left: calc(50% + 1vmin);
  top: calc(50% - 1vmin);
  animation-delay: 0.2s;
}

@keyframes loader-5 {
  0%,
  88%,
  100% {
    opacity: 0;
  }

  0% {
    transform: translateY(-6vmin);
  }

  33% {
    opacity: 1;
  }

  33%,
  88% {
    transform: translateY(3vmin);
  }
}

/**
	loader --6
**/
.loader.--6::before {
  width: var(--size-square);
  height: var(--size-square);
  background-color: var(--color);
  top: calc(50% - var(--size-square));
  left: calc(50% - var(--size-square));
  animation: loader-6 2.4s cubic-bezier(0, 0, 0.24, 1.21) infinite;
}

@keyframes loader-6 {
  0%,
  100% {
    transform: none;
  }

  25% {
    transform: translateX(100%);
  }

  50% {
    transform: translateX(100%) translateY(100%);
  }

  75% {
    transform: translateY(100%);
  }
}

/**
	loader --7
**/
.loader.--7::before,
.loader.--7::after {
  width: var(--size-square);
  height: var(--size-square);
  background-color: var(--color);
}

.loader.--7::before {
  top: calc(50% - var(--size-square));
  left: calc(50% - var(--size-square));
  animation: loader-6 2.4s cubic-bezier(0, 0, 0.24, 1.21) infinite;
}

.loader.--7::after {
  top: 50%;
  left: 50%;
  animation: loader-7 2.4s cubic-bezier(0, 0, 0.24, 1.21) infinite;
}

@keyframes loader-7 {
  0%,
  100% {
    transform: none;
  }

  25% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(-100%) translateY(-100%);
  }

  75% {
    transform: translateY(-100%);
  }
}

/**
	loader --8
**/
.loader.--8::before,
.loader.--8::after {
  width: var(--size-dot);
  height: var(--size-dot);
  border-radius: 50%;
  background-color: var(--color);
}

.loader.--8::before {
  top: calc(50% + 4vmin);
  animation: loader-8-1 0.8s cubic-bezier(0.06, 0.01, 0.49, 1.18) infinite;
}

.loader.--8::after {
  opacity: 0;
  top: calc(50% - 2vmin);
  animation: loader-8-2 0.8s cubic-bezier(0.46, -0.1, 0.27, 1.07) 0.2s infinite;
}

@keyframes loader-8-1 {
  0%,
  55%,
  100% {
    opacity: 0;
  }

  0% {
    transform: scale(0.2);
  }

  22% {
    opacity: 1;
  }

  33%,
  55% {
    transform: scale(1) translateY(-6vmin);
  }
}

@keyframes loader-8-2 {
  0%,
  100% {
    opacity: 0;
  }

  33% {
    opacity: 0.3;
  }

  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(4);
  }
}

/**
	loader --9
**/
.loader.--9::before,
.loader.--9::after {
  width: var(--size-dot);
  height: var(--size-dot);
  border-radius: 50%;
  background-color: var(--color);
  animation: loader-9 0.42s cubic-bezier(0.39, 0.31, 0, 1.11) infinite;
}

.loader.--9::before {
  left: calc(50% - var(--size-dot) - 1.6vmin);
}

.loader.--9::after {
  left: calc(50% + 1.6vmin);
  animation-delay: 0.12s;
}

@keyframes loader-9 {
  0%,
  100% {
    opacity: 0;
  }

  0% {
    transform: translate(-4vmin, -4vmin);
  }

  66% {
    opacity: 1;
  }

  66%,
  100% {
    transform: none;
  }
}

.fa-animation-duration-3s {
  --fa-animation-duration: 3s;
}

.text-solana-green {
  color: #33a382;
}

.text-solana-purple {
  color: #9945ff;
}

@keyframes greenPurple {
  0% {
    color: #33a382;
  }

  40% {
    color: #33a382;
  }

  50% {
    color: #9945ff;
  }

  90% {
    color: #9945ff;
  }

  100% {
    color: #33a382;
  }
}

.rotating-solana-colors {
  animation: greenPurple 12s infinite;
}

.snow-bg {
  position: relative;
}

.snow-bg:after {
  content: "";
  display: block;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background-image: url("https://library.elementor.com/resources/christmas-snow-effect/s1.png"),
    url("https://library.elementor.com/resources/christmas-snow-effect/s2.png"),
    url("https://library.elementor.com/resources/christmas-snow-effect/s3.png");
  animation: snow 40s linear infinite;
}

@keyframes snow {
  0% {
    background-position: 0px 0px, 0px 0px, 0px 0px;
  }

  50% {
    background-position: 500px 500px, 100px 200px, -100px 150px;
  }

  100% {
    background-position: 500px 1000px, 200px 400px, -100px 300px;
  }
}

.fw-semibold {
  font-weight: 500;
}

.fw-bolder {
  font-weight: 600;
}

.fw-bold {
  font-weight: 700;
}

.avatar {
  position: relative;
  display: inline-block;
}

.avatar .avatar-name {
  background-color: var(--falcon-avatar-name-bg);
  position: absolute;
  text-align: center;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  display: block;
}

.avatar .avatar-name > span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.avatar .avatar-emoji {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar .avatar-emoji > span {
  transform: translateY(2%);
  display: inline-block;
}

.avatar img {
  display: block;
}

.avatar .avatar-name,
.avatar img {
  width: 100%;
  height: 100%;
}

.avatar img {
  object-fit: cover;
}

.avatar[class*="status-"]:before {
  content: "";
  position: absolute;
  border-radius: 100%;
  z-index: 1;
}

.avatar.status-online:before {
  background-color: var(--falcon-success);
}

.avatar.status-offline:before {
  background-color: var(--falcon-secondary);
}

.avatar.status-away:before {
  background-color: var(--falcon-warning);
}

.avatar.status-do-not-disturb:before {
  background-color: var(--falcon-danger);
}

.avatar .avatar-button {
  background-color: var(--falcon-avatar-button-bg) !important;
  transition: all 0.2s ease-in-out;
  border: 1px solid var(--falcon-avatar-button-bg);
  font-size: 12px;
}

.avatar .avatar-button:focus,
.avatar .avatar-button:hover {
  background-color: var(--falcon-avatar-button-hover-bg) !important;
}

.avatar:hover .overlay-icon {
  opacity: 1;
}

.avatar-s {
  height: 1.25rem;
  width: 1.25rem;
}

.avatar-s .avatar-name {
  font-size: 0.4166666667rem;
}

.avatar-s .avatar-emoji {
  font-size: 1.25rem;
}

.avatar-s[class*="status-"]:before {
  border: 1px solid var(--falcon-avatar-status-border-color);
  height: 9px;
  width: 9px;
  right: -1px;
  bottom: -1px;
}

.avatar-s .rounded-soft {
  border-radius: 0.2083333333rem !important;
}

.avatar-m {
  height: 1.5rem;
  width: 1.5rem;
}

.avatar-m .avatar-name {
  font-size: 0.5rem;
}

.avatar-m .avatar-emoji {
  font-size: 1.5rem;
}

.avatar-m[class*="status-"]:before {
  border: 1px solid var(--falcon-avatar-status-border-color);
  height: 9px;
  width: 9px;
  right: -1px;
  bottom: -1px;
}

.avatar-m .rounded-soft {
  border-radius: 0.25rem !important;
}

.avatar-l {
  height: 1.75rem;
  width: 1.75rem;
}

.avatar-l .avatar-name {
  font-size: 0.5833333333rem;
}

.avatar-l .avatar-emoji {
  font-size: 1.75rem;
}

.avatar-l[class*="status-"]:before {
  border: 1px solid var(--falcon-avatar-status-border-color);
  height: 9px;
  width: 9px;
  right: -1px;
  bottom: -1px;
}

.avatar-l .rounded-soft {
  border-radius: 0.2916666667rem !important;
}

.avatar-xl {
  height: 2rem;
  width: 2rem;
}

.avatar-xl .avatar-name {
  font-size: 0.6666666667rem;
}

.avatar-xl .avatar-emoji {
  font-size: 2rem;
}

.avatar-xl[class*="status-"]:before {
  border: 2px solid var(--falcon-avatar-status-border-color);
  height: 13px;
  width: 13px;
  right: -2px;
  bottom: -2px;
}

.avatar-xl .rounded-soft {
  border-radius: 0.3333333333rem !important;
}

.avatar-2xl {
  height: 2.5rem;
  width: 2.5rem;
}

.avatar-2xl .avatar-name {
  font-size: 0.8333333333rem;
}

.avatar-2xl .avatar-emoji {
  font-size: 2.5rem;
}

.avatar-2xl[class*="status-"]:before {
  border: 2px solid var(--falcon-avatar-status-border-color);
  height: 16px;
  width: 16px;
  right: -3px;
  bottom: -3px;
}

.avatar-2xl .rounded-soft {
  border-radius: 0.4166666667rem !important;
}

.avatar-3xl {
  height: 3.5rem;
  width: 3.5rem;
}

.avatar-3xl .avatar-name {
  font-size: 1.1666666667rem;
}

.avatar-3xl .avatar-emoji {
  font-size: 3.5rem;
}

.avatar-3xl[class*="status-"]:before {
  border: 3px solid var(--falcon-avatar-status-border-color);
  height: 18px;
  width: 18px;
  right: 0;
  bottom: 0;
}

.avatar-3xl .rounded-soft {
  border-radius: 0.5833333333rem !important;
}

.avatar-4xl {
  height: 6.125rem;
  width: 6.125rem;
}

.avatar-4xl .avatar-name {
  font-size: 2.0416666667rem;
}

.avatar-4xl .avatar-emoji {
  font-size: 6.125rem;
}

.avatar-4xl[class*="status-"]:before {
  border: 3px solid var(--falcon-avatar-status-border-color);
  height: 24px;
  width: 24px;
  right: 3px;
  bottom: 3px;
}

.avatar-4xl .rounded-soft {
  border-radius: 1.0208333333rem !important;
}

.avatar-5xl {
  height: 10.5rem;
  width: 10.5rem;
}

.avatar-5xl .avatar-name {
  font-size: 3.5rem;
}

.avatar-5xl .avatar-emoji {
  font-size: 10.5rem;
}

.avatar-5xl[class*="status-"]:before {
  border: 4px solid var(--falcon-avatar-status-border-color);
  height: 32px;
  width: 32px;
  right: 8px;
  bottom: 8px;
}

.avatar-5xl .rounded-soft {
  border-radius: 1.75rem !important;
}

.progress .bg-info {
  background-color: #348aebc9 !important;
}

.light-card {
  background-color: rgb(248, 249, 250);
}

.modal-backdrop.show {
  opacity: 0.94;
}

/* Side notes for calling out things
-------------------------------------------------- */

.bs-callout {
  margin: 20px 0;
  padding: 15px 30px 15px 15px;
  border-left: 5px solid #eee;
}

.bs-callout h4 {
  margin-top: 0;
}

.bs-callout p:last-child {
  margin-bottom: 0;
}

.bs-callout code,
.bs-callout .highlight {
  background-color: #fff;
}

/* Themes for different contexts */
.bs-callout-danger {
  background-color: #fcf2f2;
  border-color: #dfb5b4;
}

.bs-callout-warning {
  background-color: #fefbed;
  border-color: #f1e7bc;
}

.bs-callout-info {
  background-color: #f0f7fd;
  border-color: #d0e3f0;
}

.floating-button {
  position: fixed;
  right: 0;
  top: calc(24% - 1em);
  padding: 10px 10px;
}

.rotated-letter {
  display: block;
  transform: rotate(-90deg);
  transform-origin: center;
  line-height: 1.15;
  margin-bottom: -5px;
  font-size: 13px;
}

/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/

.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 341px;
  /* height: 100%; */
  right: 0;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.right .modal-content {
  /* height: 100%; */
  overflow-y: auto;
  border-bottom-left-radius: 2em;
  background-color: rgba(223, 134, 56, 255);
  /* border-bottom: solid 1px black; */
  /* border-left: solid 1px black; */
}

.modal.sidebets .modal-content {
  background-color: rgba(223, 134, 56, 255);
}

.modal.history .modal-content {
  background-color: #aaaaaa;
}

.modal.right .modal-body {
  padding: 15px 15px 22px;
  /* border-top: solid 1px black; */
}

/*Right*/
.modal.right.fade .modal-dialog {
  right: -320px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.show .modal-dialog {
  right: 0;
}

/** ----- MODAL STYLE ----- */
.modal-content {
  border-radius: 0;
  border: none;
}

.modal-header {
  border-bottom-color: #eeeeee;
  background-color: #fafafa;
}

.modal.right {
  pointer-events: none;
}

.modal.right .modal-backdrop {
  display: none;
}

.sidebets-tabs {
  position: fixed;
  /* Since it should be positioned relative to modal */
  left: -37.5px;
  /* Depending on the width of your button, adjust this value */
  z-index: 10000;
}

.btn-sidebets {
  background-color: rgba(223, 134, 56, 255);
  border-top-left-radius: 0.2em;
  border-bottom-left-radius: 0.2em;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 1000;
  /* border-top: solid 1px black;
  border-left: solid 1px black;
  border-bottom: solid 1px black; */
}

.btn-history {
  background-color: #aaaaaa;
  border-top-left-radius: 0.2em;
  border-bottom-left-radius: 0.2em;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  /* border-top: solid 1px black;
  border-left: solid 1px black;
  border-bottom: solid 1px black; */
  margin-top: 1px;
}

.btn-sidebets-selection {
  background-color: #f4d555;
  color: black;
  border: solid 2px black;
}

.btn-sidebets-selection.active {
  background-color: black;
  color: #f4d555;
  border: solid 2px black;
}

.btn-sidebets-submit {
  color: #f4d555;
  background-color: black;
  border: solid 1px black;
}

.fixed-br {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  width: auto;
  z-index: 10;
}

.color-gold {
  color: #f4d555;
}

.font-dcf-heading {
  font-family: "Bagor";
}

.font-dcf-body {
  font-family: "Recoleta";
}

.fs--1 {
  font-size: 0.75rem !important;
}

.rounded-pill-left {
  border-radius: 50rem 0 0 50rem !important;
}

.rounded-pill-right {
  border-radius: 0 50rem 50rem 0 !important;
}

.fs--2 {
  font-size: 0.6rem !important;
}

.loader {
  transform: translateZ(1px);
}

.loader:after {
  content: "";
  display: inline-block;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  font-size: 32px;
  font-weight: bold;
  background: #ffd700;
  color: #daa520;
  border: 4px double;
  box-sizing: border-box;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.1);
  animation: coin-flip 4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@keyframes coin-flip {
  0%,
  100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }

  0% {
    transform: rotateY(0deg);
  }

  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }

  100% {
    transform: rotateY(3600deg);
  }
}

.avatar-group-vertical {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.avatar-group-vertical .avatar-s:not(:first-child),
.avatar-group-vertical .avatar-m:not(:first-child),
.avatar-group-vertical .avatar-l:not(:first-child),
.avatar-group-vertical .avatar-xl:not(:first-child),
.avatar-group-vertical .avatar-2xl:not(:first-child) {
  margin-top: -0.5rem;
}

.avatar-group-vertical .avatar-3xl:not(:first-child) {
  margin-top: -1rem;
}

.avatar-group-vertical .avatar-4xl:not(:first-child) {
  margin-top: -1.8rem;
}

.avatar-group-vertical .avatar-5xl:not(:first-child) {
  margin-top: -3rem;
}

.avatar-group-vertical-dense .avatar-s:not(:first-child) {
  margin-top: -0.6rem;
}

.avatar-group-vertical-dense .avatar-m:not(:first-child) {
  margin-top: -0.8rem;
}

.avatar-group-vertical-dense .avatar-l:not(:first-child),
.avatar-group-vertical-dense .avatar-xl:not(:first-child),
.avatar-group-vertical-dense .avatar-2xl:not(:first-child) {
  margin-top: -1rem;
}

.avatar-group-vertical-dense .avatar-3xl:not(:first-child) {
  margin-top: -1.8rem;
}

.avatar-group-vertical-dense .avatar-4xl:not(:first-child) {
  margin-top: -3.3rem;
}

.avatar-group-vertical-dense .avatar-5xl:not(:first-child) {
  margin-top: -6rem;
}

.avatar-group-horizontal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.avatar-group-horizontal .avatar-s:not(:first-child),
.avatar-group-horizontal .avatar-m:not(:first-child),
.avatar-group-horizontal .avatar-l:not(:first-child),
.avatar-group-horizontal .avatar-xl:not(:first-child),
.avatar-group-horizontal .avatar-2xl:not(:first-child) {
  margin-left: 0.2rem;
}

.avatar-group-horizontal .avatar-3xl:not(:first-child) {
  margin-left: -1rem;
}

.avatar-group-horizontal .avatar-4xl:not(:first-child) {
  margin-left: -1.8rem;
}

.avatar-group-horizontal .avatar-5xl:not(:first-child) {
  margin-left: -3rem;
}

.avatar-group-horizontal-dense .avatar-s:not(:first-child) {
  margin-left: -0.6rem;
}

.avatar-group-horizontal-dense .avatar-m:not(:first-child) {
  margin-left: -0.8rem;
}

.avatar-group-horizontal-dense .avatar-l:not(:first-child),
.avatar-group-horizontal-dense .avatar-xl:not(:first-child),
.avatar-group-horizontal-dense .avatar-2xl:not(:first-child) {
  margin-left: -1rem;
}

.avatar-group-horizontal-dense .avatar-3xl:not(:first-child) {
  margin-left: -1.8rem;
}

.avatar-group-horizontal-dense .avatar-4xl:not(:first-child) {
  margin-left: -3.3rem;
}

.avatar-group-horizontal-dense .avatar-5xl:not(:first-child) {
  margin-left: -6rem;
}

.history-body .list-group-item {
  background-color: #aaaaaa !important;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.btn-sidebets-tab-bar.active {
  background-color: #f4d555;
  border-color: black;
  color: black;
}

.btn-sidebets-tab-bar {
  color: black;
  border-color: black;
  background-color: transparent;
}

.light .coin-outline {
  border-color: black !important;
}

.light .coin-outline .avatar-name {
  color: black !important;
}

.dark .coin-outline {
  border-color: white !important;
}

.dark .coin-outline .avatar-name {
  color: white !important;
}

.dark-black .coin-outline {
  border-color: white !important;
}

.dark-black .coin-outline .avatar-name {
  color: white !important;
}

.default-bg {
  background: #f5f5f5;
}

.fa-inverse-dark {
  color: #000 !important;
}

.fa-inverse-dark-black {
  color: #000 !important;
}

.link-fx:hover {
  color: #0d6efd;
}
.font-buttons {
  font-family: "Inndam";
}

.btn-yellow {
  background-color: rgba(255, 223, 84, 255);
}

.btn-pro.clicked {
  background-color: #f5f5f5;
  color: #212529 !important;
}

.btn-pro:hover {
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  color: white;
}

.dark .btn-pro:hover {
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  color: #212529;
}

.btn:focus,
.btn:active {
  outline: none;
  box-shadow: none;
}

.btn-pro {
  transition: none;
  border: solid 3px #212529 !important;
}

.dark .btn-dark.clicked {
  background-color: #212529;
  color: #f5f5f5 !important;
  border: solid 3px #f5f5f5 !important;
}

.btn-dark.clicked {
  background-color: #f5f5f5;
  color: #212529 !important;
  border: solid 3px #212529 !important;
}

.side-button.selected {
  border: solid 3px #212529 !important;
  background-color: #f5f5f5;
  color: #212529 !important;
}

.dark .side-button.selected {
  border: solid 3px #f5f5f5 !important;
  background-color: #212529;
  color: #f5f5f5 !important;
}

.side-button {
  border: solid 3px #fafafa !important;
}

/* .form-check .form-check-input:checked {
  background-color: #68686c;
  border-color: #68686c;
}

.dark .form-check .form-check-input:checked {
  border-color: white;
} */

.live-flip-bar {
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
}

.live-rate {
  text-align: center;
  color: white;
}

.flip-container {
  overflow: hidden;
}

.flip-image {
  display: inline-block;
  margin-left: 4px;
  margin-right: 0px;
  margin-top: 2px;
  height: 25px;
}

.latest-flip-image {
  border-right: 1px solid #fbf9f958;
  /* Adjust the border style as needed */
  padding-right: 5px;
  padding-left: 5px;
}

.nowrap {
  white-space: nowrap;
}

.nooverflow {
  overflow: hidden;
}

.lh-special {
  line-height: 1.7;
}

@media screen and (max-width: 770px) {
  .flip-image {
    margin-top: 0px;
    height: 20px;
  }
}

.live-data-bar {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: black;
  max-height: 40px;
  overflow: hidden;
  color: white;
}

@media screen and (min-width: 992px) {
  .live-data-bar {
    width: 100%;
    position: absolute;
  }
}

@media screen and (min-width: 768px) {
  .lh-special {
    /* line-height:1; */
    margin-top: 2px;
  }
}

.dark .live-data-bar {
  background-color: #272e34;
}

.dark-black .live-data-bar {
  background-color: #282829;
}

.live-data-bar .progress {
  height: 69% !important;
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
}

.progress-bar-head {
  background-color: #e9a63c;
  color: black;
}

.progress-bar-tail {
  background-color: #e2f8fe;
  color: black;
}

@media screen and (max-width: 992px) {
  .progress-bar-tail {
    color: transparent;
  }

  .progress-bar-head {
    color: transparent;
  }
}

.win-rate-display {
  position: absolute;
  /* Absolute positioning */
  right: 0;
  /* Align to the right edge of the parent element */
  top: 0;
  /* Align to the top of the parent element, adjust as needed */
  padding-left: 10px;
  /* Add some padding */
  padding-right: 10px;
  padding-top: 5px;
  width: 150px;
  color: white;
  text-align: right;
}

.win-rate-display:hover {
  color: rgba(255, 255, 255, 0.789);
  /* Text color in the blinked state */
}

.live-data-icon {
  color: #6c757d;
}

.dark .live-data-icon {
  color: white;
}

@keyframes blink-animation {
  0%,
  100% {
    color: black;
    /* Text color in the normal state */
  }

  50% {
    color: rgba(255, 255, 255, 0.552);
    /* Text color in the blinked state */
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s ease-out;
}

.title-size {
  overflow: visible;
  font-size: 10px;
}

.border-green {
  border: 2px #dabf0f solid !important;
  box-shadow: 0 0 5px #9ecaed;
}

.letter-spacing-1 {
  letter-spacing: 1px;
}

.font-buttons {
  font-family: "Inndam";
}

.btn-yellow {
  background-color: rgba(255, 223, 84, 255);
}

.btn-pro.clicked {
  background-color: #f5f5f5;
  color: #212529 !important;
}

.btn-pro:hover {
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  color: white;
}

.dark .btn-pro:hover {
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  color: #212529;
}

.btn:focus,
.btn:active {
  outline: none;
  box-shadow: none;
}

.btn-pro {
  transition: none;
  border: solid 3px #212529 !important;
}

.dark .btn-dark.clicked {
  background-color: #212529;
  color: #f5f5f5 !important;
  border: solid 3px #f5f5f5 !important;
}

.btn-dark.clicked {
  background-color: #f5f5f5;
  color: #212529 !important;
  border: solid 3px #212529 !important;
}

.side-button.selected {
  border: solid 3px #212529 !important;
  background-color: #f5f5f5;
  color: #212529 !important;
}

.dark .side-button.selected {
  border: solid 3px #f5f5f5 !important;
  background-color: #212529;
  color: #f5f5f5 !important;
}

.side-button {
  border: solid 3px #fafafa !important;
}

/* .form-check .form-check-input:checked {
  background-color: #68686c;
  border-color: #68686c;
}

.dark .form-check .form-check-input:checked {
  border-color: white;
} */

.live-flip-bar {
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
}

.live-rate {
  text-align: center;
  color: white;
}

.flip-container {
  overflow: hidden;
}

.flip-image {
  display: inline-block;
  margin-left: 4px;
  margin-right: 0px;
  margin-top: 2px;
  height: 25px;
}

.latest-flip-image {
  border-right: 1px solid #fbf9f958;
  /* Adjust the border style as needed */
  padding-right: 5px;
  padding-left: 5px;
}

.nowrap {
  white-space: nowrap;
}

.nooverflow {
  overflow: hidden;
}

.lh-special {
  line-height: 1.7;
}

@media screen and (max-width: 770px) {
  .flip-image {
    margin-top: 0px;
    height: 20px;
  }
}

.live-data-bar {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: black;
  max-height: 40px;
  overflow: hidden;
  color: white;
}

@media screen and (min-width: 992px) {
  .live-data-bar {
    width: 100%;
    position: absolute;
  }
}

@media screen and (min-width: 768px) {
  .lh-special {
    /* line-height:1; */
    margin-top: 2px;
  }
}

.dark .live-data-bar {
  background-color: #272e34;
}

.dark-black .live-data-bar {
  background-color: #282829;
}

.live-data-bar .progress {
  height: 69% !important;
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
}

.progress-bar-head {
  background-color: #e9a63c;
  color: black;
}

.progress-bar-tail {
  background-color: #e2f8fe;
  color: black;
}

@media screen and (max-width: 992px) {
  .progress-bar-tail {
    color: transparent;
  }

  .progress-bar-head {
    color: transparent;
  }
}

.win-rate-display {
  position: absolute;
  /* Absolute positioning */
  right: 0;
  /* Align to the right edge of the parent element */
  top: 0;
  /* Align to the top of the parent element, adjust as needed */
  padding-left: 10px;
  /* Add some padding */
  padding-right: 10px;
  padding-top: 5px;
  width: 150px;
  color: white;
  text-align: right;
}

.win-rate-display:hover {
  color: rgba(255, 255, 255, 0.789);
  /* Text color in the blinked state */
}

.live-data-icon {
  color: #6c757d;
}

.dark .live-data-icon {
  color: white;
}

@keyframes blink-animation {
  0%,
  100% {
    color: black;
    /* Text color in the normal state */
  }

  50% {
    color: rgba(255, 255, 255, 0.552);
    /* Text color in the blinked state */
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s ease-out;
}

.title-size {
  overflow: visible;
  font-size: 10px;
}

.border-green {
  border: 2px #dabf0f solid !important;
  box-shadow: 0 0 5px #9ecaed;
}

.letter-spacing-1 {
  letter-spacing: 1px;
}

.dark .arcade-navbar {
  background-color: transparent;
}

/* Custom Toggle Switch */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 28px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .toggle-slider {
  background-color: var(--primary-color, #007bff);
}

input:focus + .toggle-slider {
  box-shadow: 0 0 1px var(--primary-color, #007bff);
}

input:checked + .toggle-slider:before {
  transform: translateX(24px);
}

/* Responsive Design */
@media (max-width: 768px) {
  .settings-card {
    padding: 1rem;
  }

  .setting-item {
    padding: 0.75rem;
  }

  .setting-item-title {
    font-size: 0.95rem;
  }

  .setting-item-description {
    font-size: 0.8rem;
  }
}

/* Dark Mode Support (if needed) */
[data-theme="dark"] .settings-card {
  --bg-color: #1a1a1a;
  --item-bg: #2d2d2d;
  --item-bg-hover: #363636;
  --text-primary: #ffffff;
  --text-secondary: #a0a0a0;
}

/* Base Theme Variables */
.flips-modal {
  --primary-color: #007bff;
  --primary-hover: #0056b3;
  --accent-color: #28a745;
}

/* Light Theme Variables */
.flips-modal.theme-light {
  --bg-color: #ffffff;
  --card-bg: #ffffff;
  --text-primary: #212529;
  --text-secondary: #6c757d;
  --border-color: #dee2e6;
  --icon-color: var(--primary-color);
  --close-hover: rgba(0, 0, 0, 0.05);
  --scroll-track: #f1f1f1;
  --scroll-thumb: #c1c1c1;
  --time-label-color: var(--accent-color);
  --shadow-color: rgba(0, 0, 0, 0.12);
}

/* Dark Theme Variables */
.flips-modal.theme-dark {
  --bg-color: black;
  --card-bg: rgb(15, 23, 42);
  --text-primary: #e9ecef;
  --text-secondary: #94a3b8;
  --border-color: rgb(51, 65, 85);
  --icon-color: #00ffa3;
  --close-hover: rgb(30, 41, 59);
  --scroll-track: rgb(30, 41, 59);
  --scroll-thumb: rgb(51, 65, 85);
  --time-label-color: #00ffa3;
  --shadow-color: rgba(0, 0, 0, 0.5);
}

.flips-modal {
  border-radius: 16px !important;
  box-shadow: 0 8px 30px var(--shadow-color);
  overflow: hidden;
  background-color: var(--bg-color);
}

.flips-card {
  background: var(--card-bg);
  padding: 1.5rem;
}

.flips-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.flips-title-group {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.flips-icon {
  font-size: 1.5rem;
  color: var(--icon-color);
}

.flips-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
  color: var(--text-primary);
}

.close-button {
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;
}

.close-button:hover {
  background-color: var(--close-hover);
}

.close-button svg {
  font-size: 1.5rem;
  color: var(--text-secondary);
}

.flips-content {
  max-height: 620px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--scroll-thumb) var(--scroll-track);
}

.flips-content::-webkit-scrollbar {
  width: 6px;
}

.flips-content::-webkit-scrollbar-track {
  background: var(--scroll-track);
  border-radius: 3px;
}

.flips-content::-webkit-scrollbar-thumb {
  background: var(--scroll-thumb);
  border-radius: 3px;
}

.time-divider {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1.5rem 0;
}

.time-label {
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--time-label-color);
  white-space: nowrap;
}

.divider-line {
  height: 1px;
  flex-grow: 1;
  background-color: var(--border-color);
}

.flips-list {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  overflow: hidden;
}

/* Responsive Design */
@media (max-width: 768px) {
  .flips-card {
    padding: 1rem;
  }

  .flips-title {
    font-size: 1.1rem;
  }

  .flips-content {
    max-height: 70vh;
  }
}

/* Animation for content loading */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.flips-list {
  animation: fadeIn 0.3s ease-out;
}

/* Dark Mode Support */
[data-theme="dark"] .flips-card {
  --bg-color: #1a1a1a;
  --text-primary: #ffffff;
  --text-secondary: #a0a0a0;
  --border-color: #2d2d2d;
  --hover-bg: rgba(255, 255, 255, 0.1);
  --scroll-track: #2d2d2d;
  --scroll-thumb: #4d4d4d;
}

/* Base Theme Variables */
.settings-modal {
  --primary-color: #007bff;
  --primary-hover: #0056b3;
}

/* Light Theme Variables */
.settings-modal.theme-light {
  --bg-color: #ffffff;
  --card-bg: #ffffff;
  --item-bg: #f8f9fa;
  --item-bg-hover: #f1f3f5;
  --text-primary: #212529;
  --text-secondary: #6c757d;
  --border-color: #dee2e6;
  --toggle-bg: #ccc;
  --toggle-checked: var(--primary-color);
  --toggle-knob: #ffffff;
  --icon-color: var(--primary-color);
  --shadow-color: rgba(0, 0, 0, 0.12);
}

/* Dark Theme Variables */
.settings-modal.theme-dark {
  --bg-color: black;
  --card-bg: rgb(15, 23, 42);
  --item-bg: rgb(30, 41, 59);
  --item-bg-hover: rgb(51, 65, 85);
  --text-primary: #ffffff;
  --text-secondary: #ffffff;
  --border-color: #ffffff;
  --toggle-bg: rgb(51, 65, 85);
  --toggle-checked: #ffdf58;
  --toggle-knob: #ffffff;
  --icon-color: #ffffff;
  --shadow-color: rgba(0, 0, 0, 0.5);
}

.settings-modal {
  border-radius: 16px !important;
  box-shadow: 0 8px 30px var(--shadow-color);
  overflow: hidden;
  background-color: var(--bg-color);
}

.settings-card {
  background: var(--card-bg);
  padding: 1.5rem;
}

.settings-header {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
}

.settings-icon {
  font-size: 1.5rem;
  color: var(--icon-color);
}

.settings-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
  color: var(--text-primary);
}

.settings-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.setting-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background: var(--item-bg);
  border-radius: 12px;
  transition: all 0.2s ease;
}

.setting-item:hover {
  background: var(--item-bg-hover);
}

.setting-item-content {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.setting-item-icon {
  font-size: 1.25rem;
  color: var(--icon-color);
}

.setting-item-text {
  display: flex;
  flex-direction: column;
}

.setting-item-title {
  font-weight: 500;
  color: var(--text-primary);
}

.setting-item-description {
  font-size: 0.875rem;
  color: var(--text-secondary);
}

/* Toggle Switch Styles */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 28px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--toggle-bg);
  transition: 0.4s;
  border-radius: 34px;
}

.toggle-slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: var(--toggle-knob);
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .toggle-slider {
  background-color: var(--toggle-checked);
}

input:focus + .toggle-slider {
  box-shadow: 0 0 1px var(--toggle-checked);
}

input:checked + .toggle-slider:before {
  transform: translateX(24px);
}

/* Responsive Design */
@media (max-width: 768px) {
  .settings-card {
    padding: 1rem;
  }

  .setting-item {
    padding: 0.75rem;
  }

  .setting-item-title {
    font-size: 0.95rem;
  }

  .setting-item-description {
    font-size: 0.8rem;
  }
}

.icon-dark {
  color: #ffffff;
}

.icon-light {
  color: #000000;
}

/* Base Theme Variables */
.profile-modal {
  --primary-color: #ffdf58; /* Updated to new color */
  --discord-color: #5865f2;
  --success-color: #ffdf58;
}

/* Light Theme Variables */
.profile-modal.theme-light {
  --bg-color: #ffffff;
  --card-bg: #ffffff;
  --text-primary: #212529;
  --text-secondary: #6c757d;
  --border-color: #dee2e6;
  --input-bg: #ffffff;
  --input-border: #ced4da;
  --icon-color: var(--primary-color);
  --close-hover: rgba(0, 0, 0, 0.05);
  --button-bg: #f8f9fa;
  --button-hover: #e9ecef;
  --shadow-color: rgba(0, 0, 0, 0.12);
}

/* Dark Theme Variables */
.profile-modal.theme-dark {
  --bg-color: black;
  --card-bg: rgb(15, 23, 42);
  --text-primary: #e9ecef;
  --text-secondary: #94a3b8;
  --border-color: rgb(51, 65, 85);
  --input-bg: rgb(30, 41, 59);
  --input-border: rgb(51, 65, 85);
  --icon-color: #00ffa3;
  --close-hover: rgb(30, 41, 59);
  --button-bg: rgb(30, 41, 59);
  --button-hover: rgb(51, 65, 85);
  --shadow-color: rgba(0, 0, 0, 0.5);
  --primary-color: #fff;
  --discord-color: #5865f2;
  --success-color: #fcce0f;
}

.profile-modal {
  border-radius: 16px !important;
  box-shadow: 0 8px 30px var(--shadow-color);
  overflow: hidden;
}

.profile-card {
  background: var(--card-bg);
  padding: 1.5rem;
}

.profile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.profile-title-group {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.profile-icon {
  font-size: 1.5rem;
  color: var(--primary-color); /* This will now use #00FFA3 */
}

.profile-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
  color: var(--text-primary);
}

.close-button {
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;
}

.close-button:hover {
  background-color: var(--close-hover);
}

.close-button i {
  font-size: 1.25rem;
  color: var(--text-secondary);
}

.profile-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.profile-picture-section {
  display: flex;
  justify-content: center;
}

.profile-picture-wrapper {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid var(--primary-color); /* This will now use #00FFA3 */
}

.profile-actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.btn-discord {
  background-color: var(--discord-color);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-size: 0.9rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: opacity 0.2s ease;
}

.btn-discord:hover {
  opacity: 0.9;
  color: white;
}

.profile-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.input-group {
  border: 1px solid var(--input-border);
  border-radius: 6px;
  overflow: hidden;
}

.input-group-text {
  background-color: var(--button-bg);
  border: none;
  color: var(--text-secondary);
}

.form-control {
  background-color: var(--input-bg);
  border: none;
  color: var(--text-primary);
}

.form-control:focus {
  background-color: var(--input-bg);
  color: var(--text-primary);
  box-shadow: none;
  border-color: var(--primary-color);
}

.private-mode-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-check-label {
  color: var(--text-primary);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.info-icon {
  font-size: 0.875rem;
  color: var(--text-secondary);
}

.profile-info {
  text-align: center;
  color: var(--text-secondary);
}

.profile-footer {
  margin-top: 1.5rem;
  padding-top: 1rem;
  border-top: 1px solid var(--border-color);
}

.btn-save {
  width: 100%;
  background-color: var(--success-color);
  color: white;
  border: none;
  padding: 0.5rem;
  border-radius: 6px;
  font-weight: 500;
  transition: opacity 0.2s ease;
}

.btn-save:hover {
  opacity: 0.9;
  color: white;
}

/* Responsive Design */
@media (max-width: 768px) {
  .profile-card {
    padding: 1rem;
  }

  .profile-picture-wrapper {
    width: 100px;
    height: 100px;
  }

  .profile-title {
    font-size: 1.1rem;
  }
}

/* Animation */
.profile-modal {
  animation: modalFadeIn 0.3s ease-out;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Light Theme Button */
.theme-light .btn-save {
  background-color: #ffdf58;
  color: black;
  font-weight: 600;
  font-size: 1.1rem;
  padding: 1rem 2rem;
  border-radius: 4px;
  border: none;
  width: 100%;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.theme-light .btn-save:hover {
  background-color: #f8d43d;
  transform: translateY(-1px);
}

.theme-light .btn-save:active {
  transform: translateY(1px);
}

/* Dark Theme Button */
.theme-dark .btn-save {
  background-color: white;
  color: black; /* Changed to black */
  font-weight: 600;
  font-size: 1.1rem;
  padding: 1rem 2rem;
  border-radius: 4px;
  border: none;
  width: 100%;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.theme-dark .btn-save:hover {
  background-color: #f1f1f1;
  transform: translateY(-1px);
  color: black; /* Ensure hover state maintains black text */
}

.theme-dark .btn-save:active {
  transform: translateY(1px);
  color: black; /* Ensure active state maintains black text */
}

/* Shared Footer Styles */
.profile-footer {
  padding-top: 1.5rem;
  border-top: 1px solid var(--border-color);
}

/* Base Theme Variables */
.ranking-modal {
  --primary-color: #ffdf58;
}

/* Light Theme Variables */
.ranking-modal.theme-light {
  --bg-color: #ffffff;
  --card-bg: #ffffff;
  --text-primary: #1a1a1a;
  --text-secondary: #666666;
  --border-color: #e5e7eb;
  --row-hover: #f8f9fa;
  --nav-button-bg: #f8f9fa;
  --nav-button-hover: #e9ecef;
  --crown-color: #ffd700;
  --rank-badge-bg: #f8f9fa;
  --shadow-color: rgba(0, 0, 0, 0.12);
}

/* Dark Theme Variables */
.ranking-modal.theme-dark {
  --bg-color: black;
  --card-bg: rgb(15, 23, 42);
  --text-primary: #ffffff;
  --text-secondary: #94a3b8;
  --border-color: rgba(255, 255, 255, 0.1);
  --row-hover: rgba(255, 255, 255, 0.05);
  --nav-button-bg: rgba(255, 255, 255, 0.1);
  --nav-button-hover: rgba(255, 255, 255, 0.15);
  --crown-color: #ffd700;
  --rank-badge-bg: rgba(255, 255, 255, 0.1);
  --shadow-color: rgba(0, 0, 0, 0.5);
}

.ranking-modal {
  border-radius: 16px !important;
  box-shadow: 0 8px 30px var(--shadow-color);
  overflow: hidden;
}

.ranking-card {
  background: var(--card-bg);
  padding: 1.5rem;
}

.ranking-header {
  text-align: center;
  margin-bottom: 1.5rem;
}

.ranking-title {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--text-primary);
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
}

.title-icon {
  font-size: 1.25rem;
}

.date-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.date-range {
  color: var(--text-secondary);
  font-size: 0.9rem;
  min-width: 200px;
  text-align: center;
}

.nav-button {
  background: var(--nav-button-bg);
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-primary);
  cursor: pointer;
  transition: all 0.2s ease;
}

.nav-button:hover {
  background: var(--nav-button-hover);
}

.ranking-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.ranking-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  transition: background-color 0.2s ease;
}

.ranking-row:hover {
  background-color: var(--row-hover);
}

.player-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.rank-badge {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--rank-badge-bg);
  border-radius: 50%;
  color: var(--text-primary);
  font-weight: 600;
}

.crown-icon {
  color: var(--crown-color);
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.player-name {
  color: var(--text-primary);
  font-weight: 500;
}

.points {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.points-value {
  color: var(--text-primary);
  font-weight: 600;
}

.points-label {
  color: var(--text-secondary);
  font-size: 0.9rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .ranking-card {
    padding: 1rem;
  }

  .ranking-title {
    font-size: 1.25rem;
  }

  .date-range {
    font-size: 0.8rem;
    min-width: 150px;
  }

  .player-name {
    font-size: 0.9rem;
  }
}
