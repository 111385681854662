@import url(https://fonts.googleapis.com/css?family=Mountains+of+Christmas:700);

.christmas-modal{
  background-color: transparent !important;
  border-radius: 50px !important; 
  overflow: hidden;
  background: #304d65;
}

.christmas-modal-backdrop {
  max-width: 100%;
}